import type { UpdateContractResponse } from "../../../FieldSave";
import type { FieldValue } from "../../../model/ContractViewModel";

export interface UpdateSalesContractFromFinnResponse {
  status: FinnUpdateStatusEnum;
  updates: SectionFieldUpdate[];
  updateResponse: UpdateContractResponse;
}

export enum FinnUpdateStatusEnum {
  Success = 1,
  MissingFinnCode = 2,
  MismatchLicenseplateNumber = 3,
}

export type SectionFieldUpdate = {
  name: string;
  fieldUpdate: FieldUpdate;
};

export type FieldUpdate = {
  name: string;
  value: FieldValue;
};
